<template>
  <div>
    <v-btn icon color="primary" @click="$emit('click:camera')" v-if="camera">
      <v-icon>mdi-camera</v-icon>
    </v-btn>
    <base-tooltip :color="value ? 'primary' : 'grey'" bottom :disabled="$vuetify.breakpoint.xs">
      <template #activator="{ on }">
        <div v-on="on" class="d-inline">
          <v-badge :overlap="overlap" :content="count" :value="count" v-bind="$attrs">
            <v-btn
              :class="value ? 'active' : ''"
              :fab="!icon"
              :icon="icon"
              x-small
              @click="$emit('input', !value)"
              :color="value ? 'primary' : 'grey'"
              ><v-icon size="18">
                {{ iconComputed }}
              </v-icon></v-btn
            >
          </v-badge>
        </div>
      </template>
      <span>Gallery</span>
    </base-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    camera: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      required: true
    },
    icon: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 0
    },
    overlap: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    iconComputed() {
      if (this.icon) {
        if (this.value) {
          return '$gallery';
        }
        return '$galleryGrey';
      }
      return '$galleryWhite';
    }
  }
};
</script>

<style scoped lang="scss">
// .active {
//   transform: rotate(45deg);
// }
</style>
